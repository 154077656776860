var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addActivity"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess,"footerShow":_vm.footerShow},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"活动名称","rules":[
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],"prop":"activityName"}},[_c('v-input',{attrs:{"placeholder":"请输入活动名称","width":250,"maxlength":50},model:{value:(_vm.form.activityName),callback:function ($$v) {_vm.$set(_vm.form, "activityName", $$v)},expression:"form.activityName"}})],1),_c('el-form-item',{attrs:{"label":"活动地点","rules":[
          { required: false, message: '请输入活动地点', trigger: 'blur' },
        ],"prop":"placeId"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('v-select',{attrs:{"placeholder":"请选择活动地点","options":_vm.areaList,"filterable":"","clearable":""},on:{"change":_vm.selectPlace},model:{value:(_vm.form.placeId),callback:function ($$v) {_vm.$set(_vm.form, "placeId", $$v)},expression:"form.placeId"}})],1)]),_c('el-form-item',{attrs:{"label":"活动场次","rules":[
          { required: true, message: '请输入活动场次', trigger: 'blur' },
        ],"prop":"scheduleList"}},[_c('div',{staticClass:"times"},_vm._l((_vm.form.scheduleList),function(item,index){return _c('div',{key:index,staticClass:"areaTimes"},[_c('div',{staticClass:"areaTimes-l"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("活动人数")]),_c('div',{staticClass:"item-r"},[_c('el-form-item',{attrs:{"rules":[
                      {
                        required: true,
                        message: '请输入活动人数',
                        trigger: 'blur',
                      },
                    ],"prop":`scheduleList.${index}.limitCount`}},[_c('v-input',{attrs:{"placeholder":"请输入活动人数， 0-表示不限制","width":250,"min":"0","type":"number"},model:{value:(item.limitCount),callback:function ($$v) {_vm.$set(item, "limitCount", $$v)},expression:"item.limitCount"}})],1)],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("是否限制单次报名参与人数")]),_c('div',{staticClass:"item-r"},[_c('v-radio',{attrs:{"radioObj":_vm.radioObj},on:{"change":function($event){return _vm.change($event, item)}},model:{value:(item.isLimit),callback:function ($$v) {_vm.$set(item, "isLimit", $$v)},expression:"item.isLimit"}}),(item.isLimit == 1)?_c('div',{staticClass:"inputPeople"},[_c('v-input',{attrs:{"placeholder":"请输入限制人数","width":160,"min":"0","max":item.limitCount,"type":"number"},model:{value:(item.perLimitCount),callback:function ($$v) {_vm.$set(item, "perLimitCount", $$v)},expression:"item.perLimitCount"}})],1):_vm._e()],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("报名时间")]),_c('div',{staticClass:"item-r"},[_c('el-form-item',{attrs:{"rules":[
                      {
                        type: 'array',
                        required: true,
                        message: '请选择报名时间',
                        trigger: 'blur',
                      },
                    ],"prop":`scheduleList.${index}.applyDate`}},[_c('v-date-picker',{attrs:{"type":"datetimerange"},model:{value:(item.applyDate),callback:function ($$v) {_vm.$set(item, "applyDate", $$v)},expression:"item.applyDate"}})],1)],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("活动时间")]),_c('div',{staticClass:"item-r"},[_c('el-form-item',{attrs:{"rules":[
                      {
                        type: 'array',
                        required: true,
                        message: '请选择活动时间',
                        trigger: 'blur',
                      },
                    ],"prop":`scheduleList.${index}.activityDate`}},[_c('v-date-picker',{attrs:{"type":"datetimerange"},model:{value:(item.activityDate),callback:function ($$v) {_vm.$set(item, "activityDate", $$v)},expression:"item.activityDate"}})],1)],1)])])])}),0)]),_c('el-form-item',{attrs:{"label":"封面图片","rules":[
          { required: true, message: '请上传活动图片', trigger: 'blur' },
        ],"prop":"activityPicture"}},[_c('div',{attrs:{"id":"activityImg"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.activityPicture,"tips":"建议上传686px*238px图片"},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "activityPicture", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "activityPicture", $event)}}})],1)]),_c('el-form-item',{attrs:{"label":"活动文本","rules":[
          { required: false, message: '请输入活动文本', trigger: 'blur' },
        ],"prop":"content"}},[_c('v-ueditor',{attrs:{"maxLength":20000,"isShowImg":false},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('el-form-item',{attrs:{"label":"活动图片","rules":[
          {
            required: false,
            message: '请上传活动图片',
            trigger: ['change', 'blur'],
          },
        ],"prop":"imageUrls"}},[_c('v-upload',{attrs:{"limit":9,"imgUrls":_vm.form.imageUrls},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "imageUrls", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "imageUrls", $event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }