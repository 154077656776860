<template>
  <div class="addActivity">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      :footerShow="footerShow"
      @update="update"
    >
      <!-- <el-form-item
        label="选择园区"
        :rules="[{ required: false, message: '请选择园区', trigger: 'blur' }]"
        prop="authSpaceIds"
      > -->
      <!-- <community-select v-model="form.communityId" /> -->
      <!-- <v-cascader
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
        />
      </el-form-item> -->
      <el-form-item
        label="活动名称"
        :rules="[
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ]"
        prop="activityName"
      >
        <v-input
          placeholder="请输入活动名称"
          v-model="form.activityName"
          :width="250"
          :maxlength="50"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="活动地点"
        :rules="[
          { required: false, message: '请输入活动地点', trigger: 'blur' },
        ]"
        prop="placeId"
      >
        <div style="display: flex">
          <v-select
            placeholder="请选择活动地点"
            :options="areaList"
            filterable
            clearable
            v-model="form.placeId"
            @change="selectPlace"
          />
        </div>
      </el-form-item>
      <el-form-item
        label="活动场次"
        :rules="[
          { required: true, message: '请输入活动场次', trigger: 'blur' },
        ]"
        prop="scheduleList"
      >
        <div class="times">
          <div
            class="areaTimes"
            v-for="(item, index) in form.scheduleList"
            :key="index"
          >
            <div class="areaTimes-l">
              <div class="item">
                <div class="item-l">活动人数</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请输入活动人数',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`scheduleList.${index}.limitCount`"
                  >
                    <v-input
                      placeholder="请输入活动人数， 0-表示不限制"
                      v-model="item.limitCount"
                      :width="250"
                      min="0"
                      type="number"
                    ></v-input>
                  </el-form-item>
                </div>
              </div>
              <div class="item">
                <div class="item-l">是否限制单次报名参与人数</div>
                <div class="item-r">
                  <!-- <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请选择是否限制单次报名参与人数',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`scheduleList.${index}.isLimit`"
                  > -->
                  <v-radio
                    :radioObj="radioObj"
                    v-model="item.isLimit"
                    @change="change($event, item)"
                  ></v-radio>
                  <!-- </el-form-item> -->
                  <div class="inputPeople" v-if="item.isLimit == 1">
                    <!-- <el-form-item
                      :rules="[
                        {
                          required: true,
                          message: '请输入限制人数',
                          trigger: 'blur',
                        },
                      ]"
                      :prop="`scheduleList.${index}.perLimitCount`"
                    > -->
                    <v-input
                      placeholder="请输入限制人数"
                      v-model="item.perLimitCount"
                      :width="160"
                      min="0"
                      :max="item.limitCount"
                      type="number"
                    ></v-input>
                    <!-- </el-form-item> -->
                    <!-- <span>(请输入限制人数)</span> -->
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="item-l">报名时间</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        type: 'array',
                        required: true,
                        message: '请选择报名时间',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`scheduleList.${index}.applyDate`"
                  >
                    <v-date-picker
                      v-model="item.applyDate"
                      type="datetimerange"
                    ></v-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="item">
                <div class="item-l">活动时间</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        type: 'array',
                        required: true,
                        message: '请选择活动时间',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`scheduleList.${index}.activityDate`"
                  >
                    <v-date-picker
                      v-model="item.activityDate"
                      type="datetimerange"
                    ></v-date-picker>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item
        label="封面图片"
        :rules="[
          { required: true, message: '请上传活动图片', trigger: 'blur' },
        ]"
        prop="activityPicture"
      >
        <div id="activityImg">
          <v-upload
            :limit="1"
            :imgUrls.sync="form.activityPicture"
            tips="建议上传686px*238px图片"
          />
        </div>
      </el-form-item>
      <el-form-item
        label="活动文本"
        :rules="[
          { required: false, message: '请输入活动文本', trigger: 'blur' },
        ]"
        prop="content"
      >
        <v-ueditor
          :maxLength="20000"
          v-model="form.content"
          :isShowImg="false"
        />
      </el-form-item>
      <el-form-item
        label="活动图片"
        :rules="[
          {
            required: false,
            message: '请上传活动图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="imageUrls"
      >
        <v-upload :limit="9" :imgUrls.sync="form.imageUrls" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  getBuildListURL,
  activityApplyInfoUrl,
  publishActivityUrl,
  activityPointSettingList,
  areaListUrl,
} from "./api.js";
import {
  activityTypeList,
  sceneIdsListMap,
  sceneIdsListOps,
  sceneIdsList,
  reportScenes,
  reportScenesMap,
  reportScenesOps,
  setIsTopOps,
} from "./map.js";
export default {
  name: "talentActivitiesForm",
  data() {
    return {
      isTopOps: setIsTopOps(),
      footerShow: true,
      reportScenesList: reportScenesOps(),
      activityTypeList,
      activitySceneTypeRadio: [
        { name: "1", value: "党建" },
        { name: "2", value: "公益" },
        { name: "3", value: "社群" },
        { name: "4", value: "商业" },
        { name: "5", value: "其他" },
      ],
      radioObj: [
        { name: "2", value: "不限制" },
        { name: "1", value: "限制" },
      ],
      radioApplyObj: [
        { name: "2", value: "否" },
        { name: "1", value: "是" },
      ],
      activityPointSettingList: [],
      submitConfig: {
        queryUrl: activityApplyInfoUrl,
        submitUrl: publishActivityUrl,
      },
      areaList: [],
      form: {
        activityCategory: 1, // 写死为热门活动
        activityName: "",
        activityAddress: "",
        publisherId: this.$store.state.app.userInfo.userId,
        communityId: "P1C2A2S1D1V1",
        activityType: 0,
        tenantId: this.$store.state.app.userInfo.tenantId,
        limitCount: null,
        perLimitCount: 0,
        applyStartDate: "",
        applyEndDate: "",
        startDate: "",
        endDate: "",
        isLimit: "2",
        scheduleList: [
          {
            limitCount: "",
            perLimitCount: 0,
            applyDate: [],
            activityDate: [],
            pointsSettingId: null,
            isLimit: "2",
          },
        ],
        placeId: null,
        imageUrls: "",
        activityPicture: "",
        content: "",
        applyId: null,
      },
      sceneIdsList: sceneIdsListOps(),
      // -----------------------
      props: {
        multiple: false,
        label: "name",
        value: "id",
        children: "children",
      },
      residentsOps: [], // 用户
      merchantsOps: [], // 商圈
      allOps: [], // 用户+商圈
      spacePathIds: [], // 添加treeIds的数据
      sectionOps: [], // 用于显示的数据
      authSpaceIds: [],
    };
  },
  async mounted() {
    this.getAreaList();
    await this.getBuildList(10);
    const { applyId, communityId } = this.$route.query;
    if (applyId !== undefined) {
      this.$refs.formPanel.getData({ applyId });
      this.footerShow = false;
    } else {
      this.form.placeId = null;
    }
    await this.getActivityPointSettingList();
    this.$setBreadList(applyId ? "查看" : "新增");
  },
  watch: {
    // 选中类别集合
    sceneIdsList: {
      handler(val) {
        console.log(val, this.form, this.onOff, "-----");

        const checkOps = val.filter((v) => v.checked);
        this.form.sceneIds =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },
    reportScenesList: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.statisticsScenes =
          checkOps.length > 0 ? checkOps.map((v) => v.value).join(",") : "";
      },
      deep: true,
    },
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.form.communityId = val[val.length - 1];
        console.log(val);
        console.log(this.form.communityId);
      },
      deep: true,
    },
  },
  methods: {
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      console.log(data);
      return data;
    },
    async getBuildList(type) {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=${type}`
      );
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data);
        console.log(res.data, opt, "opt---");
        // 10 用户 11 商圈
        if (type === 10) {
          this.residentsOps = opt;
        } else if (type === 11) {
          this.merchantsOps = opt;
        } else {
          this.allOps = opt;
        }
        this.sectionOps = opt;
      }
    },
    selectPlace() {
      this.areaList.forEach((ele) => {
        if (ele.placeId == this.form.placeId) {
          this.form.activityAddress = ele.placeName;
        } else if (this.form.placeId.length == 0) {
          this.form.activityAddress = "";
        }
      });
    },
    checkChange() {},

    changeActivitySceneType() {},
    pickerOptions() {},
    outRoom(item, index) {
      this.form.scheduleList.splice(index, 1);
    },
    addRoom() {
      this.form.scheduleList.push({
        limitCount: "",
        perLimitCount: 0,
        applyDate: [],
        activityDate: [],
        pointsSettingId: null,
        isLimit: "2",
      });
    },
    changeApply(value) {
      console.log(value);
      this.form.allowApplyMulti = String(value);
    },
    change(value, item) {
      console.log(value, item);
      item.isLimit = String(value);
      this.$forceUpdate();
    },
    async getActivityPointSettingList() {
      this.activityPointSettingList = [];
      let res = await this.$axios.get(`${activityPointSettingList}`);
      if (res.code === 200) {
        if (res.data) {
          res.data.forEach((item) => {
            this.activityPointSettingList.push({
              value: item.settingId.toString(),
              label: item.settingName,
            });
          });
        }
      } else {
        if (res.msg) {
          this.$message.error(res.msg);
        }
      }
    },
    getAreaList() {
      this.areaList = [];
      this.$axios.get(`${areaListUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.label = item.placeName;
            item.value = item.placeId;
            this.areaList.push(item);
          });
        } else {
        }
      });
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      this.authSpaceIds = [];
      this.form.communityId = data.communityId;
      const ids = this.form.communityId;
      this.spacePathIds.forEach((item) => {
        if (ids == item.id) {
          this.authSpaceIds = item.treeIds;
        }
      });
      if (this.form.scheduleList) {
        this.form.scheduleList.forEach((ele) => {
          ele.pointsSettingId = ele.pointsSettingId
            ? String(ele.pointsSettingId)
            : "";
          if (ele.perLimitCount && Number(ele.perLimitCount) > 0) {
            ele.isLimit = "1";
          } else {
            ele.isLimit = "2";
          }
          ele.activityDate = [];
          ele.activityDate.push(new Date(ele.startDate));
          ele.activityDate.push(new Date(ele.endDate));
          ele.applyDate = [];
          ele.applyDate.push(new Date(ele.applyStartDate));
          ele.applyDate.push(new Date(ele.applyEndDate));
        });
      } else {
        this.form.scheduleList = [
          {
            limitCount: "",
            perLimitCount: 0,
            applyDate: [],
            activityDate: [],
            pointsSettingId: null,
            isLimit: "2",
          },
        ];
      }

      if (this.form.pointsSettingId) {
        if (this.form.pointsSettingId == 0) {
          this.form.pointsSettingId = null;
        } else {
          this.form.pointsSettingId = this.form.pointsSettingId.toString();
        }
      } else {
        this.form.pointsSettingId = null;
      }

      if (this.form.allowApplyMulti) {
        this.form.allowApplyMulti = String(this.form.allowApplyMulti);
      } else {
        this.form.allowApplyMulti = "1";
      }
    },
    submitBefore() {
      if (this.form.placeId == "" && this.form.activityAddress == "") {
        this.$message("请输入活动地点");
        return;
      }
      console.log(this.form.scheduleList, "this.form.sche---");
      this.form.scheduleList.forEach((ele) => {
        if (!ele.activityDate) {
          ele.startDate = "";
          ele.endDate = "";
        }
        if (!ele.applyDate) {
          ele.applyStartDate = "";
          ele.applyEndDate = "";
        }
        ele.applyStartDate =
          ele.applyDate.length > 0
            ? ele.applyDate[0].format("yyyy-MM-dd HH:mm")
            : "";
        ele.applyEndDate =
          ele.applyDate.length > 0
            ? ele.applyDate[1].format("yyyy-MM-dd HH:mm")
            : "";
        ele.startDate =
          ele.activityDate.length > 0
            ? ele.activityDate[0].format("yyyy-MM-dd HH:mm")
            : "";
        ele.endDate =
          ele.activityDate.length > 0
            ? ele.activityDate[1].format("yyyy-MM-dd HH:mm")
            : "";
      });
      this.form.applyStartDate =
        this.form.scheduleList[0].applyDate[0].format("yyyy-MM-dd HH:mm");
      this.form.applyEndDate =
        this.form.scheduleList[0].applyDate[1].format("yyyy-MM-dd HH:mm");
      this.form.startDate =
        this.form.scheduleList[0].activityDate[0].format("yyyy-MM-dd HH:mm");
      this.form.endDate =
        this.form.scheduleList[0].activityDate[1].format("yyyy-MM-dd HH:mm");
      this.form.perLimitCount = this.form.scheduleList[0].limitCount;
      this.form.limitCount = this.form.scheduleList[0].limitCount;
      this.form.scheduleList[0].perLimitCount =
        this.form.scheduleList[0].limitCount;
      // this.form.scheduleList.forEach((v) => {
      //   v.applyStartDate = v.applyDate ? v.applyDate[0] : "";
      //   v.applyEndDate = v.applyDate ? v.applyDate[1] : "";
      //   v.startDate = v.activityDate ? v.activityDate[0] : "";
      //   v.endDate = v.activityDate ? v.activityDate[1] : "";
      // });
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
  .check-panel {
    display: flex;
  }
  /deep/ .el-form-item__label {
    width: 160px !important;
  }
  .inputPeople {
    display: flex;
  }
  .areaTimes {
    display: flex;
    align-items: flex-end;

    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .areaTimes-l {
      margin-right: 20px;
    }
    .areaTimes-r {
      height: 46px;
      /deep/ .v-button {
        margin-right: 10px;
      }
    }
    .item {
      display: flex;
      align-content: center;
      margin: 20px 0;
      .item-l {
        margin-right: 10px;
      }
      .item-r {
        display: flex;
      }
    }
  }
}
</style>
